import './App.css';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

function App() {
  return (
    <Router>
      <Route exact path="/landing">
        <Redirect to="/" />
      </Route>
    </Router>
  );
}

export default App;
